import { render, staticRenderFns } from "./JourneyContent2.vue?vue&type=template&id=058f5d4c&scoped=true&"
import script from "./JourneyContent2.vue?vue&type=script&lang=js&"
export * from "./JourneyContent2.vue?vue&type=script&lang=js&"
import style0 from "./JourneyContent2.vue?vue&type=style&index=0&id=058f5d4c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "058f5d4c",
  null
  
)

export default component.exports