<template>
  <div class="hero">
    <h1 class="h2 t-1">See why commuting has never been this simple</h1>
    <picture class="hero-image">
        <source srcset="~@/assets/j_hero.webp" type="image/webp">
        <source srcset="~@/assets/j_hero.jpg" type="image/jpeg">
        <img src="~@/assets/j_hero.jpg" alt="Solution" />
    </picture>
  </div>
</template>

<script>
export default {
  name: 'JourneyHero'
}
</script>

<style lang="scss" scoped>
.hero {
  @include grid;
  margin-top: 272px;
  @include laptop {
    margin-top: 192px;
  }
  @include phone {
    margin-top: 132px;
  }
  .t-1 {
    grid-column: 2 / 8;
    grid-row: 1 / 2;
    margin-bottom: 52px;
    @include laptop {
      margin-bottom: 36px;
    }
    @include phone {
      grid-column: 2 / 6;
    }
  }
  .hero-image {
    grid-column: 1 / 15;
    grid-row: 2 / 3;
    @include tablet {
      grid-column: 1 / 11;
    }
    @include phone {
      grid-column: 1 / 7;
    }
    img {
      width: 100%;
      vertical-align: bottom;
      @include phone {
        height: 66.7vw;
        object-fit: cover;
      }
    }
  }
}

</style>
