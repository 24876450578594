<template>
  <div class="journey page">
    <JourneyHero/>
    <JourneyContent1/>
    <JourneyContent2/>
    <JourneyCallToAction/>
    <NavFooter/>
  </div>
</template>

<script>
import JourneyHero from '../components/JourneyHero'
import JourneyContent1 from '../components/JourneyContent1'
import JourneyContent2 from '../components/JourneyContent2'
import JourneyCallToAction from '../components/JourneyCallToAction'
import NavFooter from '../components/NavFooter'

export default {
  name: 'Journey',
  components: {
    JourneyHero,
    JourneyContent1,
    JourneyContent2,
    JourneyCallToAction,
    NavFooter
  }
}
</script>

<style lang="scss" scoped>

</style>
