<template>
  <div class="content-2 up-1" v-scrollanimate>
    <div class="video-container">
      <iframe class="iframe" src="https://player.vimeo.com/video/642697860?h=0bb933c463&autoplay=0&loop=1&background=0" width="100%" frameborder="0" allow="fullscreen"></iframe>
    </div>
    <h4 class="h3 t-0">Commute</h4>
    <div class="l-1 h4">01</div>
    <div class="t-1 h4">Park smart</div>
    <div class="p-1">Your carpark is not just somewhere off the highway—it’s literally built into the highway. Easy to reach. Fast to park. Easy to exit, to any direction</div>
    <div class="l-2 h4">02</div>
    <div class="t-2 h4">Switch smart</div>
    <div class="p-2">You don’t need to walk through endless carparks to switch to public transportation—just take the elevator. Your bus, train, or ride is right there, without the need to cross any traffic lanes.</div>
    <div class="l-3 h4">03</div>
    <div class="t-3 h4">Ride smart</div>
    <div class="p-3">To reach your desired destination, you don’t need to take a shuttle to a public transportation hub—you’re already in one. Train, bus, or shared mobility are all here, ready to take you anywhere.</div>
  </div>
</template>

<script>
export default {
  name: 'JourneyContent2'
}
</script>

<style lang="scss" scoped>
.content-2 {
  @include grid;
  margin-bottom: 128px;
  @include laptop {
    margin-bottom: 96px;
  }
  @include tablet {
    margin-bottom: 64px;
  }
  .video-container {
    grid-column: 1 / 15;
    grid-row: 1 / 2;
    position: relative;
    display: block;
    width: 100%;
    overflow: hidden;
    @include tablet {
      grid-column: 1 / 11;
    }
    @include phone {
      grid-column: 1 / 7;
    }
    &::before {
      display: block;
      content: "";
      padding-top: 41%;
    }
    .iframe {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
  .t-0 {
    grid-column: 2 / 6;
    grid-row: 2 / 3;
    margin-top: 128px;
    @include laptop {
      margin-top: 96px;
    }
    @include tablet {
      margin-top: 64px;
    }
  }
  .l-1 {
    grid-column: 7 / 8;
    grid-row: 2 / 3;
    margin-top: 128px;
    @include laptop {
      margin-top: 96px;
    }
    @include tablet {
      grid-column: 2 / 3;
      grid-row: 3 / 4;
      margin-top: 42px;
    }
    @include phone {
      grid-column: 2 / 3;
    }
  }
  .t-1 {
    grid-column: 8 / 11;
    grid-row: 2 / 3;
    margin-top: 128px;
    @include laptop {
      margin-top: 96px;
    }
    @include tablet {
      grid-column: 3 / 6;
      grid-row: 3 / 4;
      margin-top: 42px;
    }
    @include phone {
      grid-column: 3 / 6;
    }
  }
  .p-1 {
    grid-column: 8 / 12;
    grid-row: 3 / 4;
    margin-top: 16px;
    @include tablet {
      grid-column: 3 / 7;
      grid-row: 4 / 5;
    }
    @include phone {
      grid-column: 3 / 6;
    }
  }
  .l-2 {
    grid-column: 7 / 8;
    grid-row: 4 / 5;
    margin-top: 64px;
    @include tablet {
      grid-column: 2 / 3;
      grid-row: 5 / 6;
      margin-top: 42px;
    }
    @include phone {
      grid-column: 2 / 3;
    }
  }
  .t-2 {
    grid-column: 8 / 11;
    grid-row: 4 / 5;
    margin-top: 64px;
    @include tablet {
      grid-column: 3 / 6;
      grid-row: 5 / 6;
      margin-top: 42px;
    }
    @include phone {
      grid-column: 3 / 6;
    }
  }
  .p-2 {
    grid-column: 8 / 12;
    grid-row: 5 / 6;
    margin-top: 16px;
    @include tablet {
      grid-column: 3 / 7;
      grid-row: 6 / 7;
    }
    @include phone {
      grid-column: 3 / 6;
    }
  }
  .l-3 {
    grid-column: 7 / 8;
    grid-row: 6 / 7;
    margin-top: 64px;
    @include tablet {
      grid-column: 2 / 3;
      grid-row: 7 / 8;
      margin-top: 42px;
    }
    @include phone {
      grid-column: 2 / 3;
    }
  }
  .t-3 {
    grid-column: 8 / 11;
    grid-row: 6 / 7;
    margin-top: 64px;
    @include tablet {
      grid-column: 3 / 6;
      grid-row: 7 / 8;
      margin-top: 42px;
    }
    @include phone {
      grid-column: 3 / 6;
    }
  }
  .p-3 {
    grid-column: 8 / 12;
    grid-row: 7 / 8;
    margin-top: 16px;
    @include tablet {
      grid-column: 3 / 7;
      grid-row: 8 / 9;
    }
    @include phone {
      grid-column: 3 / 6;
    }
  }
}

</style>
