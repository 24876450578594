<template>
  <div class="content up-1" v-scrollanimate>
    <h2 class="h4 t-1">Riding into town through RoadHub’s CPRS makes your trip both fast and comfortable. You can enjoy the privacy and speed of your car outside the city, and the efficiency of public transportation inside of it.</h2>
    <div class="p-1">A combined SRI and CPRS complex on the city’s outskirts provides commuters with the best of both worlds. Now you can leave home with a private car and head on to the highway. As you approach the new interchange just outside the city, one of its exits leads you to a carpark built right within its core. But it’s more than that — it’s also a major hub of public transportation and shared mobility.</div>
  </div>
</template>

<script>
export default {
  name: 'JourneContent1'
}
</script>

<style lang="scss" scoped>
.content {
  @include grid;
  margin-top: 128px;
  @include laptop {
    margin-top: 96px;
  }
  @include tablet {
    margin-top: 64px;
  }
  .t-1 {
    grid-column: 8 / 13;
    grid-row: 1 / 2;
    @include tablet {
      grid-column: 2 / 8;
    }
    @include phone {
      grid-column: 2 / 6;
    }
  }
  .p-1 {
    grid-column: 8 / 13;
    grid-row: 2 / 3;
    margin-top: 54px;
    margin-bottom: 128px;
    @include laptop {
      margin-top: 32px;
      margin-bottom: 96px;
    }
    @include tablet {
      grid-column: 2 / 8;
    }
    @include phone {
      margin-top: 26px;
      margin-bottom: 64px;
      grid-column: 2 / 6;
    }
  }
}

</style>
